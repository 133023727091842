import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)">
          <path d="M3518 5713 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
          <path d="M3753 5713 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
          <path d="M3418 5703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3688 5693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3205 5655 c-25 -12 -26 -14 -5 -9 14 4 29 10 35 15 12 12 5 11 -30
          -6z"/>
          <path d="M4108 5653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M4150 5640 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
          0 -3 -5 5 -10z"/>
          <path d="M3138 5633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3100 5614 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
          -10 -7 -10 -16z"/>
          <path d="M3195 5620 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
          <path d="M4200 5619 c0 -5 11 -9 25 -9 14 0 25 2 25 4 0 2 -11 6 -25 8 -14 3
          -25 1 -25 -3z"/>
          <path d="M4517 5469 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
          <path d="M4636 5387 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
          <path d="M2668 5383 c-2 -5 -1 -26 3 -48 l6 -40 2 48 c1 44 -3 57 -11 40z"/>
          <path d="M4690 5335 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
          <path d="M2605 5330 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
          -13 -4 -16 -10z"/>
          <path d="M4720 5322 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
          7 -15 8 -15 2z"/>
          <path d="M2564 5295 c-18 -14 -18 -14 4 -8 12 3 22 9 22 14 0 12 -3 11 -26 -6z"/>
          <path d="M2489 5223 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
          <path d="M4815 5220 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
          -7 -4 -4 -10z"/>
          <path d="M4850 5190 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
          -2 0 0 -9 6 -20z"/>
          <path d="M2427 5153 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
          -9 2 -12 -2z"/>
          <path d="M2360 5075 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
          -9 -7 -16 -15z"/>
          <path d="M3618 5083 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
          <path d="M3488 5073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3820 5070 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
          <path d="M3448 5063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M4960 5061 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
          <path d="M3883 5053 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
          <path d="M3958 5033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3308 5023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M4995 5001 c8 -17 19 -33 25 -36 5 -3 1 10 -11 29 -25 43 -36 48 -14
          7z"/>
          <path d="M3270 5010 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
          <path d="M2306 4993 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
          <path d="M4050 4997 c14 -8 27 -12 30 -10 7 6 -23 23 -40 23 -8 0 -4 -6 10
          -13z"/>
          <path d="M3206 4981 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
          <path d="M4090 4986 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
          -15 -2 -15 -4z"/>
          <path d="M2280 4959 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
          -5 -10 -11z"/>
          <path d="M3139 4939 c-19 -16 -20 -18 -4 -10 34 17 51 31 39 31 -7 0 -22 -9
          -35 -21z"/>
          <path d="M4155 4940 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
          -7 -4 -4 -10z"/>
          <path d="M2255 4913 l-17 -28 23 22 c12 12 20 25 17 28 -3 3 -13 -7 -23 -22z"/>
          <path d="M5040 4931 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
          <path d="M2217 4853 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
          -9 2 -12 -2z"/>
          <path d="M4290 4856 c0 -4 7 -14 15 -22 8 -9 15 -11 15 -6 0 5 -7 15 -15 22
          -8 7 -15 9 -15 6z"/>
          <path d="M5086 4847 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
          <path d="M4370 4780 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
          -4 -4 -4 -10z"/>
          <path d="M5136 4750 c3 -14 10 -29 15 -34 14 -16 10 6 -7 34 l-15 25 7 -25z"/>
          <path d="M2055 4687 c-63 -29 -99 -102 -83 -166 29 -114 156 -149 246 -68 55
          51 49 166 -13 213 -39 30 -109 39 -150 21z"/>
          <path d="M5131 4684 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M2260 4681 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
          3 -10 2 -10 -4z"/>
          <path d="M4450 4680 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
          -4 -4 -4 -10z"/>
          <path d="M3648 4653 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
          <path d="M3558 4643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M4500 4605 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
          -4 -7 -4 -15z"/>
          <path d="M3395 4590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
          -8 -4 -11 -10z"/>
          <path d="M3929 4581 c13 -12 28 -21 34 -21 12 0 11 0 -28 24 l-30 17 24 -20z"/>
          <path d="M2302 4550 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
          <path d="M3437 4569 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
          <path d="M3265 4493 c-25 -26 -45 -52 -45 -58 0 -5 13 6 29 25 16 19 42 45 57
          57 16 13 24 23 17 23 -7 0 -33 -21 -58 -47z"/>
          <path d="M4001 4533 c0 -5 14 -19 32 -32 l32 -25 -29 32 c-31 33 -36 37 -35
          25z"/>
          <path d="M4540 4535 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
          -5 8 -10 11 -10 5z"/>
          <path d="M2286 4483 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
          <path d="M5205 4479 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
          <path d="M5232 4460 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M3261 4437 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
          -17z"/>
          <path d="M4080 4454 c1 -12 39 -61 40 -51 0 5 -9 20 -20 34 -11 14 -20 22 -20
          17z"/>
          <path d="M2260 4435 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
          -9 -7 -16 -15z"/>
          <path d="M5241 4414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M5206 4405 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
          <path d="M2200 4380 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
          <path d="M4130 4375 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
          -5 8 -10 11 -10 5z"/>
          <path d="M2658 4363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3165 4359 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
          <path d="M2598 4353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M5252 4340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M4612 4325 c0 -11 3 -29 8 -40 11 -25 11 1 0 35 -6 20 -8 21 -8 5z"/>
          <path d="M2500 4309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
          -5 -10 -11z"/>
          <path d="M2425 4270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
          -8 -4 -11 -10z"/>
          <path d="M2043 4230 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
          <path d="M3131 4254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M3112 4210 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M4198 4183 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
          <path d="M4171 4174 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M2411 4154 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M1785 4140 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
          -13 -4 -16 -10z"/>
          <path d="M1870 4130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
          -4 -4 -4 -10z"/>
          <path d="M2062 4105 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
          <path d="M1741 4064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M3102 4055 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
          <path d="M1712 4040 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M1932 4040 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M4202 4020 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M1741 4014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M3111 3984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M1730 3986 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
          13z"/>
          <path d="M2440 3978 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
          <path d="M1865 3950 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
          -13 -4 -16 -10z"/>
          <path d="M1785 3940 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
          -7 -4 -4 -10z"/>
          <path d="M4176 3925 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
          <path d="M2148 3913 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
          <path d="M2925 3901 c-65 -30 -89 -63 -89 -124 -1 -94 58 -153 150 -151 78 2
          134 64 134 148 0 37 -6 52 -32 82 -31 35 -82 64 -110 64 -7 0 -31 -9 -53 -19z"/>
          <path d="M3141 3904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M2260 3890 c0 -5 9 -14 20 -20 11 -6 20 -7 20 -3 0 5 -9 14 -20 21
          -11 7 -20 8 -20 2z"/>
          <path d="M5252 3860 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
          <path d="M2317 3839 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
          <path d="M2358 3769 c9 -23 18 -37 20 -31 3 10 -24 72 -32 72 -3 0 3 -19 12
          -41z"/>
          <path d="M4111 3794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M4081 3784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path d="M4084 3748 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
          <path d="M3220 3757 c0 -2 14 -16 32 -33 l32 -29 -25 32 c-20 27 -39 41 -39
          30z"/>
          <path d="M4009 3723 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
          <path d="M5222 3701 c-7 -21 -12 -45 -11 -52 0 -8 7 8 15 35 9 26 13 50 11 52
          -2 2 -9 -13 -15 -35z"/>
          <path d="M2751 3715 c0 -5 6 -21 14 -35 8 -14 14 -20 14 -15 0 6 -6 21 -14 35
          -8 14 -14 21 -14 15z"/>
          <path d="M4035 3710 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
          -13 -4 -16 -10z"/>
          <path d="M2726 3691 c-3 -5 1 -11 9 -14 9 -4 12 -1 8 9 -6 16 -10 17 -17 5z"/>
          <path d="M3316 3666 c10 -8 21 -12 24 -10 5 6 -21 24 -34 24 -6 0 -1 -6 10
          -14z"/>
          <path d="M3995 3669 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
          <path d="M3361 3636 c2 -2 15 -9 29 -15 24 -11 24 -11 6 3 -16 13 -49 24 -35
          12z"/>
          <path d="M2365 3610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
          -8 -4 -11 -10z"/>
          <path d="M3688 3593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M2347 3573 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
          -9 2 -12 -2z"/>
          <path d="M2825 3560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
          -7 -4 -4 -10z"/>
          <path d="M3563 3553 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
          <path d="M3738 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M5175 3549 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
          <path d="M3663 3543 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
          <path d="M2180 3434 c1 -16 29 -60 29 -44 0 8 -6 24 -14 34 -8 11 -15 16 -15
          10z"/>
          <path d="M2222 3340 c7 -10 14 -18 15 -16 6 6 -9 36 -18 36 -5 0 -4 -9 3 -20z"/>
          <path d="M3040 3330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
          -4 -4 -4 -10z"/>
          <path d="M5067 3323 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
          <path d="M3080 3301 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
          <path d="M5045 3269 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
          <path d="M3155 3250 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
          0 -7 -4 -4 -10z"/>
          <path d="M2280 3246 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
          13z"/>
          <path d="M5017 3243 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
          -9 2 -12 -2z"/>
          <path d="M3195 3230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
          -7 -4 -4 -10z"/>
          <path d="M3230 3210 c8 -5 22 -10 30 -10 13 0 13 1 0 10 -8 5 -22 10 -30 10
          -13 0 -13 -1 0 -10z"/>
          <path d="M3308 3173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3945 3160 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
          -12 -4 -23 -9z"/>
          <path d="M4970 3159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
          -5 -10 -11z"/>
          <path d="M3400 3150 c8 -5 26 -10 39 -10 32 0 24 6 -19 14 -25 4 -31 3 -20 -4z"/>
          <path d="M3828 3133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3528 3123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3788 3123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M4915 3090 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
          -23 -16z"/>
          <path d="M2415 3060 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
          0 -7 -4 -4 -10z"/>
          <path d="M2450 3015 c22 -24 30 -29 30 -22 0 8 -38 47 -45 47 -5 0 2 -11 15
          -25z"/>
          <path d="M4832 3000 c-6 -11 -12 -22 -12 -25 0 -11 20 6 30 26 14 25 -2 25
          -18 -1z"/>
          <path d="M2530 2944 c0 -12 38 -50 44 -43 2 2 -7 15 -20 29 -13 13 -24 20 -24
          14z"/>
          <path d="M4753 2920 c-11 -16 -10 -18 1 -14 15 6 30 34 18 34 -4 0 -13 -9 -19
          -20z"/>
          <path d="M4675 2850 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
          -8 -4 -11 -10z"/>
          <path d="M2890 2706 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
          <path d="M2870 2684 c0 -2 14 -10 30 -19 17 -9 30 -12 30 -7 0 4 -13 13 -30
          19 -16 6 -30 10 -30 7z"/>
          <path d="M2990 2626 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
          <path d="M3068 2623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3068 2588 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
          <path d="M3205 2550 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
          <path d="M4055 2535 c-16 -7 -23 -14 -15 -14 8 0 29 6 45 14 17 7 23 14 15 14
          -8 0 -28 -6 -45 -14z"/>
          <path d="M3418 2523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M3898 2503 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
          <path d="M3578 2473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
          <path d="M1672 1978 l3 -303 137 0 136 0 31 32 c29 30 31 38 31 104 0 81 -21
          136 -57 150 -25 9 -32 29 -10 29 8 0 24 12 38 26 20 21 24 38 27 102 7 144
          -17 162 -213 162 l-125 0 2 -302z m201 112 c-4 -43 -6 -45 -36 -45 -31 0 -32
          0 -31 50 1 47 3 50 28 49 14 0 26 5 26 10 0 6 4 4 9 -4 4 -8 6 -35 4 -60z m-3
          -183 c0 -5 6 -5 13 -1 9 6 8 3 -1 -8 -7 -9 -12 -32 -10 -50 3 -31 1 -33 -29
          -33 -18 0 -33 3 -33 8 0 4 0 27 0 51 0 42 1 43 30 42 16 -1 30 -5 30 -9z"/>
          <path d="M2052 1978 l3 -303 65 0 65 0 3 303 2 302 -70 0 -70 0 2 -302z"/>
          <path d="M2232 1978 l3 -303 123 -3 122 -3 0 70 0 70 -56 3 -56 3 1 200 c1
          110 -1 215 -5 233 l-6 32 -64 0 -64 0 2 -302z"/>
          <path d="M2522 1978 l3 -303 120 0 120 0 3 67 3 67 -56 3 -57 3 -1 230 -2 230
          -68 3 -67 3 2 -303z"/>
          <path d="M2864 2270 c-12 -4 -31 -21 -43 -36 -20 -26 -21 -37 -21 -260 l0
          -232 30 -30 c25 -25 41 -32 95 -37 158 -17 215 22 215 146 l0 49 -64 0 c-63 0
          -65 -1 -68 -27 -3 -24 -7 -28 -37 -28 l-34 0 2 152 c2 106 -1 153 -9 158 -19
          11 10 25 37 18 15 -3 23 -1 23 8 1 10 3 9 10 -2 5 -9 8 -25 5 -36 -4 -23 25
          -33 96 -33 l39 0 0 48 c0 123 -33 152 -172 151 -45 0 -93 -4 -104 -9z"/>
          <path d="M3276 2268 c-15 -48 -105 -542 -106 -574 0 -20 5 -22 62 -20 61 1 63
          2 68 31 5 29 7 30 60 30 53 0 55 -1 60 -30 5 -30 6 -30 70 -30 52 0 65 3 63
          15 -15 97 -100 548 -109 578 -2 8 -28 12 -84 12 -56 0 -82 -4 -84 -12z m95
          -235 c0 -10 3 -38 6 -63 12 -89 10 -103 -15 -105 -13 -1 -25 2 -28 6 -7 12 19
          179 28 179 4 0 8 -8 9 -17z m166 -345 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
          4 -12 1 -19z"/>
          <path d="M3640 2267 c-36 -18 -60 -73 -60 -138 0 -108 34 -158 133 -199 58
          -24 64 -32 70 -90 2 -22 -2 -25 -33 -25 -31 0 -35 3 -38 28 -3 26 -5 27 -68
          27 l-64 0 0 -37 c0 -57 17 -107 46 -134 24 -22 34 -24 126 -24 l100 0 34 39
          c32 37 34 43 34 115 -1 42 -6 85 -13 97 -29 49 -58 75 -108 94 -69 27 -78 37
          -81 85 -3 40 -3 40 32 40 33 0 35 -2 32 -30 l-3 -30 71 -3 70 -3 0 56 c0 61
          -18 105 -52 129 -28 20 -190 22 -228 3z m225 -317 c3 -5 1 -10 -4 -10 -6 0
          -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
          <path d="M3960 1981 l0 -301 23 -5 c13 -4 43 -5 68 -3 l44 3 3 121 3 120 29
          -4 30 -4 0 -120 0 -119 68 3 67 3 3 303 2 302 -70 0 -70 0 0 -115 c0 -63 -3
          -116 -7 -116 -5 -1 -19 -2 -33 -3 l-24 -1 -1 115 0 115 -67 3 -68 3 0 -300z"/>
          <path d="M4542 1978 l3 -303 138 1 137 1 30 33 30 34 0 228 c0 255 -5 277 -61
          297 -18 6 -88 11 -156 11 l-123 0 2 -302z m180 168 c19 -1 20 -10 20 -166 l0
          -165 -32 0 -32 0 1 163 c0 89 0 167 1 173 0 5 5 7 11 3 7 -4 20 -7 31 -8z"/>
          <path d="M5016 2263 c-6 -21 -79 -396 -99 -505 -8 -43 -11 -80 -9 -83 3 -3 34
          -5 69 -5 l63 0 0 30 c0 29 2 30 45 32 25 0 51 1 57 2 7 1 15 -12 18 -29 5 -30
          6 -30 70 -30 l65 0 -3 35 c-2 19 -10 64 -18 100 -17 78 -60 299 -69 355 -3 22
          -11 57 -16 78 l-10 37 -79 0 c-66 0 -79 -3 -84 -17z m99 -280 c4 -38 11 -73
          16 -79 5 -6 7 -18 4 -26 -8 -21 -72 -8 -66 13 2 8 8 47 14 87 5 39 13 72 17
          72 4 0 11 -30 15 -67z"/>
          <path d="M5260 2215 c0 -45 4 -65 13 -66 6 -1 23 -3 37 -4 14 -1 30 -3 35 -4
          6 0 9 -85 7 -236 l-3 -236 65 3 66 2 2 234 c3 235 3 235 26 243 15 6 21 5 17
          -1 -3 -6 5 -10 19 -10 25 0 26 2 26 70 l0 70 -155 0 -155 0 0 -65z"/>
          <path d="M5636 2263 c-7 -28 -42 -201 -77 -383 -17 -91 -33 -175 -36 -188 -5
          -22 -4 -23 63 -20 67 3 69 4 74 32 5 28 7 29 60 29 53 0 55 -1 60 -29 5 -28 7
          -29 73 -32 l68 -3 -7 38 c-3 21 -12 61 -19 88 -7 28 -20 95 -29 150 -9 55 -22
          125 -28 155 -7 30 -12 57 -13 60 0 3 -4 23 -8 45 -15 79 -11 75 -97 75 -66 0
          -79 -3 -84 -17z m101 -285 c6 -40 12 -80 14 -88 3 -11 -5 -15 -31 -15 -26 0
          -34 4 -31 15 2 8 8 48 14 88 6 39 14 72 17 72 3 0 11 -33 17 -72z"/>
          <path d="M2520 1400 l0 -120 30 0 30 0 0 120 0 120 -30 0 -30 0 0 -120z"/>
          <path d="M2624 1495 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
          10z"/>
          <path d="M4652 1481 c13 -14 24 -28 26 -30 1 -2 2 7 2 21 0 20 -6 27 -26 31
          l-25 4 23 -26z"/>
          <path d="M5244 1495 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
          10z"/>
          <path d="M2122 1493 c-40 -16 -57 -142 -24 -180 9 -11 28 -24 42 -28 22 -7 23
          -6 11 8 -9 12 -12 42 -9 112 1 52 1 95 -2 94 -3 0 -11 -3 -18 -6z"/>
          <path d="M2218 1405 c3 -65 0 -100 -8 -110 -24 -28 10 -16 41 14 25 25 29 37
          29 84 0 40 -6 61 -21 81 -37 47 -44 34 -41 -69z"/>
          <path d="M3148 1390 l-3 -110 33 0 32 0 0 110 0 110 -29 0 -30 0 -3 -110z"/>
          <path d="M3245 1440 c0 -48 3 -60 16 -60 43 0 65 73 31 103 -36 32 -47 22 -47
          -43z"/>
          <path d="M4550 1467 c-21 -43 -4 -78 46 -97 57 -21 76 -38 68 -64 -6 -20 -5
          -20 12 -4 22 21 23 74 1 96 -9 9 -36 23 -59 32 -30 11 -44 23 -49 40 l-6 25
          -13 -28z"/>
          <path d="M4367 1473 c-12 -12 -8 -168 5 -181 7 -7 26 -12 43 -11 16 0 24 3 18
          6 -9 3 -13 33 -13 99 0 92 -1 94 -23 94 -13 0 -27 -3 -30 -7z"/>
          <path d="M2330 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M2418 1423 c8 -3 12 -28 12 -74 l0 -69 30 0 c29 0 30 1 30 49 0 76
          -13 101 -52 100 -18 0 -27 -3 -20 -6z"/>
          <path d="M2620 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M2720 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M2808 1423 c8 -3 12 -28 12 -74 l0 -69 30 0 c30 0 30 0 30 56 0 73
          -12 94 -53 93 -18 0 -26 -3 -19 -6z"/>
          <path d="M2930 1410 c-12 -12 -20 -33 -20 -55 0 -48 29 -75 78 -74 31 1 33 2
          13 8 -29 8 -41 44 -35 101 5 45 -5 51 -36 20z"/>
          <path d="M3000 1396 c0 -29 3 -33 25 -33 19 0 25 5 25 21 0 25 -17 46 -37 46
          -8 0 -13 -13 -13 -34z"/>
          <path d="M3354 1415 c-8 -21 3 -45 20 -45 20 0 29 16 21 40 -7 23 -33 27 -41
          5z"/>
          <path d="M3420 1361 c0 -71 6 -81 49 -81 14 0 16 8 11 63 -6 71 -13 87 -41 87
          -17 0 -19 -7 -19 -69z"/>
          <path d="M3530 1424 c0 -4 12 -37 27 -75 19 -50 32 -69 45 -69 23 0 23 23 1
          95 -15 50 -20 55 -45 55 -15 0 -28 -3 -28 -6z"/>
          <path d="M3700 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M3789 1408 c4 -13 7 -47 6 -75 -1 -52 -1 -53 27 -53 28 0 28 1 28 59
          0 78 -6 91 -41 91 -25 0 -28 -2 -20 -22z"/>
          <path d="M3878 1418 c7 -7 12 -39 12 -75 0 -62 0 -63 28 -63 l27 0 -3 67 c-4
          71 -11 83 -51 83 -20 0 -22 -3 -13 -12z"/>
          <path d="M4002 1414 c-16 -11 -22 -25 -22 -55 0 -47 27 -79 67 -79 25 0 26 1
          8 14 -16 11 -19 26 -19 75 1 34 -2 61 -5 61 -4 0 -17 -7 -29 -16z"/>
          <path d="M4070 1395 c0 -31 3 -35 25 -35 19 0 25 5 25 23 0 25 -17 47 -37 47
          -8 0 -13 -13 -13 -35z"/>
          <path d="M4160 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M4248 1423 c8 -3 12 -28 12 -74 l0 -69 30 0 c30 0 30 0 30 56 0 73
          -12 94 -53 93 -18 0 -26 -3 -19 -6z"/>
          <path d="M4750 1410 c-12 -12 -20 -33 -20 -55 0 -48 29 -75 78 -74 21 1 28 3
          17 6 -32 8 -36 17 -36 82 -1 65 -7 73 -39 41z"/>
          <path d="M4820 1395 c0 -31 3 -35 25 -35 19 0 25 5 25 23 0 25 -17 47 -37 47
          -8 0 -13 -13 -13 -35z"/>
          <path d="M4910 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M5005 1409 c-8 -23 7 -45 24 -35 6 4 11 18 11 32 0 30 -26 32 -35 3z"/>
          <path d="M5070 1423 c0 -4 12 -38 27 -75 18 -48 31 -68 44 -68 23 0 29 28 13
          60 -6 14 -15 40 -19 58 -6 27 -11 32 -36 32 -16 0 -29 -3 -29 -7z"/>
          <path d="M5240 1355 l0 -75 30 0 30 0 0 75 0 75 -30 0 -30 0 0 -75z"/>
          <path d="M5346 1408 c-9 -12 -16 -36 -16 -53 0 -51 24 -75 73 -74 28 1 33 3
          16 6 -21 3 -28 11 -32 37 -3 19 -2 50 3 70 7 31 5 36 -10 36 -11 0 -26 -10
          -34 -22z"/>
          <path d="M5426 1408 c-9 -35 -8 -38 13 -38 23 0 32 19 19 43 -12 23 -24 21
          -32 -5z"/>
          <path d="M5500 1410 c-44 -44 -13 -130 47 -130 25 0 26 1 8 14 -15 11 -18 25
          -17 75 2 65 -6 73 -38 41z"/>
          <path d="M5570 1395 c0 -31 3 -35 25 -35 19 0 25 5 25 23 0 25 -17 47 -37 47
          -8 0 -13 -13 -13 -35z"/>
          <path d="M3352 1341 c-8 -4 -12 -19 -10 -32 2 -19 10 -25 34 -27 29 -3 30 -2
          26 33 -4 36 -21 45 -50 26z"/>
          <path d="M4540 1315 c0 -28 3 -30 38 -32 20 -1 30 1 22 4 -8 3 -25 18 -37 32
          l-23 26 0 -30z"/>
          <path d="M3520 1244 c-10 -11 -10 -17 0 -29 19 -22 35 -18 36 9 2 29 -20 41
          -36 20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
